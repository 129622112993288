import React, { lazy, Suspense } from 'react';
import Typing from '../Typing/Typing';
// import Loading from '../Loading/Loading';
import './Home.css';

const D3 = lazy(() => import('../D3/D3'));

export default function Home() {
    return (
        <>
            <div className='bg'>
                <div className='f5'>ATOMS</div>
                <div style={{ height: 'auto', width: '100%' }}>
                    <Suspense fallback={<span />}>
                        <D3 />
                    </Suspense>
                </div>
                <footer className='footer'>
                    <div className='row'>
                        <div className='type col-1'>
                            <div>&gt;&nbsp;<Typing /></div>
                        </div>
                        <div className='col-2' style={{ color: 'grey', fontSize: '0.9em' }}>
                            <small>&copy; 2020</small>
                        </div>
                        <div className='col-3' style={{ color: 'grey', fontSize: '0.9em' }}>
                            <div className='email'>
                                <span><span className='name'>phani</span>@atomss.in</span>
                                <span><span className='name'>ram</span>@atomss.in</span>
                            </div>
                            <div className='icon'><span role='img' aria-label='emoji'>&#9997;</span></div>

                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}