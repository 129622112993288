import React from 'react';
import Typed from 'typed.js';
import './Typing.css';

export default class Typing extends React.Component {
    componentDidMount() {
        // You can pass other options here, such as typing speed, back speed, etc.
        const options = {
            strings: [
                "Javascript",
                "Python",
                "React",
                "Angular",
                "Node",
                "Django",
                "Deno",
                "ML",
                "GraphQL"
            ],
            typeSpeed: 100,
            backSpeed: 100,
            smartBackspace: true,
            startDelay: 140,
            backDelay: 500,
            loop: true,
            loopCount: Infinity,
            showCursor: true,
            cursorChar: '|',
            autoInsertCss: true,
        };
        // this.el refers to the <span> in the render() method
        this.typed = new Typed(this.el, options);
    }

    componentWillUnmount() {
        // Make sure to destroy Typed instance on unmounting
        // to prevent memory leaks
        this.typed.destroy();
    }

    render() {
        return (
            <>
                <span className="wrap">
                    <span className="type-wrap">
                        <span
                            style={{ whiteSpace: "pre", color: 'yellow' }}
                            ref={(el) => {
                                this.el = el;
                            }}
                        />
                    </span>
                </span>
            </>
        );
    }
}
